<template>
  <v-data-table :headers="headers" :items="items" hide-default-footer>
    <template v-slot:[`item.title`]="props">
      <v-edit-dialog
        :return-value.sync="props.item.title"
        @save="save"
        @cancel="cancel"
        @open="open"
        @close="close"
      >
        {{ props.item.title }}
        <template v-slot:input>
          <v-text-field
            v-model="props.item.title"
            label="Edit"
            single-line
            counter
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:[`item.type`]="props">
      <v-edit-dialog
        :return-value.sync="props.item.type"
        @save="save"
        @cancel="cancel"
        @open="open"
        @close="close"
      >
        {{ props.item.type }}
        <template v-slot:input>
          <!-- TODO need to update item on change -->
          <v-select
            :items="types"
            v-model="props.item.type"
            label="Type"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:[`item.timeTaken`]="props">
      <v-edit-dialog
        :return-value.sync="props.item.timeTaken"
        large
        persistent
        @save="save"
        @cancel="cancel"
        @open="open"
        @close="close"
      >
        <div>{{ props.item.timeTaken }}</div>
        <template v-slot:input>
          <div class="mt-4 text-h6">Update timeTaken</div>
          <v-text-field
            v-model="props.item.timeTaken"
            label="Edit"
            single-line
            counter
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "SprintTable",
  props: {
    value: Object,
  },
  methods: {
    open() {},
    close() {},
    cancel() {},
    save() {},
  },
  data: function () {
    const types = [];
    const items = [];

    console.log("this.value", this.value);

    // items = this.value.items

    // this.value.groups.forEach((group) => {
    //   const type = group.title;
    //   types.push(type)

    //   group.items.forEach((item) => {
    //     items.push({
    //       link: item.link,
    //       title: item.title,
    //       type: type,
    //       user: item.user,
    //       timeTaken: item.timeTaken,
    //     });
    //   });
    // });

    items.push({
      link: "-",
      title: "Get Trackk back to working state",
      type: "Roadmap",
      user: "Tolga",
      estimate: "13",
      timeTaken: "8",
    });

    items.push({
      link: "-",
      title: "Performance test the servers with 1,000 staff per company",
      type: "BAU",
      user: "Tolga",
      estimate: "13",
      timeTaken: "",
    });

    items.push({
      link: "-",
      title: "Get Trackk back to working state",
      type: "Roadmap",
      user: "Tolga",
      estimate: "13",
      timeTaken: "",
    });

    return {
      items,
      types,
      headers: [
        { text: "Link", value: "link" },
        { text: "Title", value: "title", width: 400 },
        { text: "Type", value: "type" },
        { text: "User", value: "user" },
        // { text: "Estimate (hrs)", value: "estimate" },
        { text: "Estimate", value: "estimate" },
        // { text: "Time taken (hrs)", value: "timeTaken" },
        { text: "Time taken", value: "timeTaken" },
      ],
    };
  },
};
</script>